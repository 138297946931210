<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <b-overlay :show="loading" rounded="sm" no-fade>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Entries</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
            <b-button v-show="$Can('invoice_send')" variant="primary" :to="{ name: 'invoice-add' }">
              Add Record
            </b-button>
          </b-col>
          

          <!-- Search -->
          <b-col cols="12" md="4">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="filter" class="d-inline-block mr-1" placeholder="Search..." />
              <v-select v-model="statusFilter" :options="statusOptions" class="invoice-filter-select" placeholder="Select Status">
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>

          <b-col cols="12" xl="2">
                    

                      <!-- Select Client -->
                            <flat-pickr v-model="startDate" title="Date" style="width:100%; background-color:white"  :config="{ dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }" class="form-control invoice-edit-input" />

                    
                    </b-col>

                    <b-col cols="12" xl="2">
                   
                            <flat-pickr v-model="endDate" style="width:100%;  background-color:white"  :config="{ dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }" class="form-control invoice-edit-input" />
                    </b-col>

        </b-row>
      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="invoiceList"
        responsive
        :filter="filter || statusFilter"
        filter-debounce="250"
        @filtered="onFiltered"
        @row-selected="onRowSelected"
        :filter-included-fields="filterOn"
        selectable
        select-mode="single"
        :small="true"
        head-variant="dark"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
      >
        <template #head(invoiceStatus)>
          <span>Status</span>
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link :to="{ name: 'invoice-preview', params: { id: data.item.id } }" class="font-weight-bold"> #{{ data.value }} </b-link>
        </template>

        <!-- Column: Invoice Status -->
        <template #cell(invoiceStatus)="data">
          <b-avatar :id="`invoice-row-${data.item.id}`" size="32" :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.status).variant}`">
            <feather-icon :icon="resolveInvoiceStatusVariantAndIcon(data.item.status).icon" />
          </b-avatar>
          <b-tooltip :target="`invoice-row-${data.item.id}`" placement="top">
            <p class="mb-0" v-if="data.item.status == 'A'">
              Draft
            </p>

            <p class="mb-0" v-if="data.item.status == 'B'">
              Approved
            </p>

            <p class="mb-0">Due Date: {{ data.item.dueDate }}</p>
          </b-tooltip>
        </template>

        <!-- Column: Client -->
        <template #cell(contact)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.value }}
            </span>
            <!-- <small class="text-muted">{{ data.item.get_customer.email }}</small> -->
          </b-media>
        </template>

        <!-- Column: Issued Date -->
        <template #cell(issuedDate)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <template #cell(tagNumber)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <template #cell(poNumber)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <template #cell(total)="data">
          <span class="text-nowrap">
            {{ formatPrice(data.value) }}
          </span>
        </template>

        <template #cell(date)="data">
          <span class="text-nowrap">
            {{ formatDate(data.value) }}
          </span>
        </template>

        <!-- Column: Balance -->
        <template #cell(balance)="data">
          <template v-if="data.value === 0">
            <b-badge pill variant="light-success">
              Paid
            </b-badge>
          </template>
          <template v-else> {{ data.value }} </template>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- <feather-icon v-show="$Can('invoice_send')" :id="`invoice-row-${data.item.id}-send-icon`" icon="SendIcon" class="cursor-pointer" size="16" />
            <b-tooltip title="Send Invoice" class="cursor-pointer" :target="`invoice-row-${data.item.id}-send-icon`" /> -->

            <!-- <feather-icon v-show="$Can('invoice_preview')" :id="`invoice-row-${data.item.id}-preview-icon`" icon="EyeIcon" size="16" class="mx-1" @click="$router.push({ name: 'invoice-preview', params: { id: data.item.id } })" />
            <b-tooltip title="Preview Invoice" :target="`invoice-row-${data.item.id}-preview-icon`" /> -->

            <!-- Dropdown -->
            <b-dropdown variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item @click="downloadInvoice(data.item.id)">
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">Download</span>
              </b-dropdown-item>
              <b-dropdown-item v-show="$Can('invoice_edit')" v-if="data.item.status != 'B' && data.item.archive != 'yes'" :to="{ name: 'invoice-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="$Can('invoice_edit')">
                <feather-icon icon="TrashIcon" />
                <span v-if="data.item.archive != 'yes'" class="align-middle ml-50" @click="archiveInvoice(data.item.id, 'yes')">Archive</span>
                <span v-else class="align-middle ml-50 " @click="archiveInvoice(data.item.id, 'no')">Unarchive</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item>
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">Duplicate</span>
            </b-dropdown-item> -->
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <a class="ml-2 mb-2 mr-2 float-right text-primary" v-if="!archive" @click="archiveOn">Show archived records</a>
      <a class="ml-2 mb-2 mr-2 float-right text-primary" v-else @click="archiveOff">Show active records</a>
    </b-overlay>
  </b-card>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BTooltip } from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import vSelect from 'vue-select';
import { onUnmounted } from '@vue/composition-api';
import store from '@/store';
import useInvoicesList from './useInvoiceList';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';

import invoiceStoreModule from '../invoiceStoreModule';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    flatPickr,
    vSelect,
  },
  setup() {
    const INVOICE_STORE_MODULE_NAME = 'invoice';

    // Register module
    if (!store.hasModule(INVOICE_STORE_MODULE_NAME)) store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_STORE_MODULE_NAME);
    });
  },

  data() {

    let currentDate = new Date();
    // Get the start date of the current month
    let startDateOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    // Get the end date of the current month
    let endDateOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    // Format the dates to Y-m-d format
    let formattedStartDateOfCurrentMonth = startDateOfCurrentMonth.getFullYear() + '-' + ('0' + (startDateOfCurrentMonth.getMonth() + 1)).slice(-2) + '-' + ('0' + startDateOfCurrentMonth.getDate()).slice(-2);
    let formattedEndDateOfCurrentMonth = endDateOfCurrentMonth.getFullYear() + '-' + ('0' + (endDateOfCurrentMonth.getMonth() + 1)).slice(-2) + '-' + ('0' + endDateOfCurrentMonth.getDate()).slice(-2);


    return {
      archive: false,
      invoiceList: [],
      dropDownUsers: [],
      startDate:formattedStartDateOfCurrentMonth,
      endDate:formattedEndDateOfCurrentMonth,

      filterOn: ['id', 'contact', 'total', 'email', 'tagNumber', 'poNumber', 'statusInvoice'],

      tableColumns: [
        { key: 'id', label: '#ID', sortable: true },
        { key: 'invoiceStatus', sortable: true },
        { key: 'contact', sortable: true },
        { key: 'total', sortable: true },
        { key: 'poNumber', sortable: true },
        { key: 'tagNumber', sortable: true },
        { key: 'date', sortable: true },
        { key: 'actions', class: 'text-center' },
      ],

      statusOptions: ['Draft', 'Approved'],
      statusFilter: null,
      perPage: localStorage.getItem('invoiceShow') ?? 10,
      currentPage: 1,
      totalRows: 0,
      selectedUserId: null,

      perPageOptions: [10, 25, 50, 100],
      filter: null,

      loading: false,
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },

  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem('invoiceShow', val);
      },
    },

    'startDate'(){
        if(this.startDate != null){
          this.fetchInvoices();
        }
      },
      'endDate'(){
        if(this.endDate != null){
          this.fetchInvoices();
        }
      }

  },

  methods: {
    formatDate(date) {
      if (date) {
        const dateObj = new Date(date);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      return '';
    },
    formatPrice(value) {
      if (value != null) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
      }
    },

    archiveOn() {
      this.loading = true;
      this.archive = true;
      store
        .dispatch('invoice/fetchArchiveInvoices', [])
        .then((res) => {
          this.invoiceList = res.data.invoices;

          this.totalRows = res.data.invoices.length;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    archiveOff() {
      this.archive = false;
      this.fetchInvoices();
    },

    archiveInvoice($id, $archive) {
      this.formShow = true;
      var info = { id: $id, archive: $archive };
      store
        .dispatch('invoice/archiveInvoice', info)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Invoice has been sent to archive.',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          if ($archive != 'yes') {
            this.archiveOn();
          } else {
            this.archiveOff();
          }
        })
        .catch((e) => {
          this.$swal({
            title: 'Error',
            text: 'Process Failed. Please try again or contact support.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK',
          });
        });
    },

    downloadInvoice($id) {
      this.download = true;
      this.formShow = true;
      var val = $id;
      store.dispatch('invoice/printInvoice', val).then((response) => {
        if (response.status == 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Invoice_' + Number(val) + '.pdf');
          document.body.appendChild(link);
          link.click();
          this.formShow = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Invoice is created!',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          this.download = false;
        }
      });
    },

    onRowSelected(item) {
      if (this.$Can('invoice_preview')) {
        router.push({ name: 'invoice-preview', params: { id: item[0].id } });
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    fetchInvoices() {
      this.loading = true;
      store
        .dispatch('invoice/fetchInvoices', {startDate:this.startDate,endDate:this.endDate})
        .then((res) => {
          this.invoiceList = res.data.invoices;
          this.totalRows = res.data.invoices.length;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    lastWeek() {
      this.loading = true;
      store
        .dispatch('invoice/lastWeek', [])
        .then((res) => {
          this.fetchInvoices();

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    resolveInvoiceStatusVariantAndIcon(status) {
      if (status === 'A') return { variant: 'danger', icon: 'SaveIcon' };
      if (status === 'B') return { variant: 'success', icon: 'CheckIcon' };
      return { variant: 'secondary', icon: 'XIcon' };
    },

    resolveClientAvatarVariant(status) {
      if (status === 'A') return 'danger';
      if (status === 'B') return 'success';

      return 'primary';
    },
  },

  created() {
    this.fetchInvoices();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
